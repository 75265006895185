"use strict";

import "select2";
import "django-select2";

const toggleDependant = function(dependant, dependee, initial) {
  if (dependee.val()) {
    dependant.removeAttr('disabled');
  } else {
    dependant.attr('disabled', true);
  }
  if (!initial) {
    dependant.val(null).change();
  }
  dependant.djangoSelect2();
}

const dependsOn = function(dependant, selector) {
  toggleDependant(dependant, $(selector), true);
  $(document).on('change', selector, function(e) {
    toggleDependant(dependant, $(this), false);
  });
}

$(function() {
  $('[data-select2-dependent-fields]').each(function(i, e) {
    var dependant = $(e);
    var dependeeSelector = '#id_' + dependant.data('depends-on');
    dependsOn(dependant, dependeeSelector);
  });
})
